<template>
  <section class="m-setting-clause m-scroll">
    <!-- 头部导航 -->
    <m-header title="条款" ></m-header>
    <div class="main">
        <p>
            Payment Methods
        </p>
        <p>
            <span>MoobyYoho.com accepts Paypal, Visa,&nbsp;MasterCard,&nbsp;Alipay and Wechat Pay.</span>
        </p>
        <p>
            <spa>MoobyYoho.com’s VISA and MASTERCATD payment system is protected by Bambora and powered by TD Bank Canada. </spa>
        </p>
        <p>
            <span>Bambora is <span >an international payments company based in Stockholm, Sweden that processes payments in 65 markets around the world.</span></span>
        </p>
        <p>
            <span >The North American office is located in Victoria, British Columbia. It began as Beanstream in 2000 and evolved into Bambora in 2015, joining 12 other companies around the world.</span>
        </p>
        <p>
            <span >In July 2017, Bambora joined the Ingenico Group, one of the world’s largest payments companies, to further streamline the payment experience.</span>
        </p>
        <p>
            <span>MoobyYoho.com’s Alipay and Wechat payment system is protected by Snappay Inc. and powered by RBC Bank Canada. </span>
        </p>
        <p>
            <span class="p-no">Snappay Inc. is a Leading Provider of Alipay &amp; WeChat Pay.</span><span> <span class="p-no">The official Canadian representative of Alipay International.</span></span>
        </p>
    </div>
  </section>
</template>

<script>
import MHeader  from '@/components/zh/m-header.vue'

export default {
  name:'Clause',
  components:{ MHeader },
  data(){
    return {
      
    }
  }
}
</script>

<style lang="less" scoped>
.m-setting-clause {
  width: 100%;
  height: 100%;
  background-color: #F5F9FF;
  padding-top:44px;
  overflow-y: auto;
  .main {
    margin-top: 12px;
    width: 100%;
    padding: 0 16px;
    font-size: 16px;
    >p:nth-child(1){font-size:19px;text-align: center;font-size: 30px;margin-bottom:50px;font-weight: 600;}
    >p:nth-child(2) span,>p:nth-child(3) span,>p:nth-child(8) span{
      font-size:16px;color:#222222;
    }
    >p:nth-child(4) span,>p:nth-child(5) span,>p:nth-child(6) span {
      letter-spacing: 1px
    }
    >p:last-child{
      vertical-align:baseline;
    }
    .p-no {
      border:none windowtext 1px;padding:0;
    }
  }
}
</style>